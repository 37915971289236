import { Case, makeEnum } from '@technicated/ts-enums'
import { HttpRequest } from './http-request'
import { Licenses } from './licenses'
import { Schede } from './schede'
import { Session } from './session'
import { Structures } from './structures'
import { Surveys } from './surveys'
import { Users } from './users'

class RouteProto {
  print(this: Route): HttpRequest {
    const request = HttpRequest.new()
      .appendingPathComponent('api')

    let requestToSend
    switch (this.case) {
      case 'licenses':
        return this.p.print(request)

      case 'municipalities':
        requestToSend = request.appendingPathComponent('items/comune')
          .withQueryParam('limit', '-1')
          .withQueryParam('fields[]', '*,pv.*')

        if (this.p.subString) {
          return requestToSend.withQueryParam('filter[nome][_icontains]', this.p.subString)
        }

        return requestToSend
      case 'searchForVatNumberOrTaxCode':
        requestToSend = request.appendingPathComponent('items/titolare_autorizzazione')
          .withQueryParam('filter[partita_iva_cf][_eq]', this.p.vatCodeOrTaxNumber)

        return requestToSend

      case 'structures':
        return this.p.print(request)

      case 'schede':
        return this.p.print(request)

      case 'structuresWithQuestionnaire':
        let structuresWithQuestionnaireRequest = request.appendingPathComponent('schede')
          .withQueryParam('page', (this.p.page + 1).toString())
          .withQueryParam('limit', this.p.limit.toString())

        if (this.p.text) {
          structuresWithQuestionnaireRequest = structuresWithQuestionnaireRequest.withQueryParam('name', this.p.text.toString())
        }

        if (this.p.year) {
          structuresWithQuestionnaireRequest = structuresWithQuestionnaireRequest.withQueryParam('year', this.p.year.toString())
        }

        return structuresWithQuestionnaireRequest

      case 'surveys':
        return this.p.print(request)

      case 'session':
        return this.p.print(request)

      case 'questionnaireYears':
        return request.appendingPathComponent('items/rilevazione_servizi')

      case 'users':
        return this.p.print(request)
    }
  }
}

export type Route = RouteProto & (
  | Case<'structuresWithQuestionnaire', { page: number, limit: number, text?: string, year?: string }>
  | Case<'licenses', Licenses>
  | Case<'structures', Structures>
  | Case<'schede', Schede>
  | Case<'municipalities', { subString?: string }>
  | Case<'searchForVatNumberOrTaxCode', { vatCodeOrTaxNumber: string }>
  | Case<'session', Session>
  | Case<'surveys', Surveys>
  | Case<'questionnaireYears'>
  | Case<'users', Users>
  )

export const Route = makeEnum<Route>({ proto: RouteProto })
