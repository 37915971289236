import { CasePath } from '@technicated/ts-enums'
import { isEqual } from 'lodash-es'
import { map, Observable } from 'rxjs'
import { ZodType } from 'zod'
import { Route } from '@shared-models'

export abstract class ApiClient {
  abstract apiRequest(route: Route): Observable<object>

  decodedApiRequest<T>(route: Route, schema: ZodType<T>): Observable<T> {
    return this.apiRequest(route).pipe(
      map((data) => schema.parse(data)),
    )
  }

  noContentApiRequest(route: Route): Observable<null> {
    return this.apiRequest(route).pipe(
      map((response) => {
        if (response !== null) {
          console.warn('Value returned from API call when using "noContentApiRequest"', response)
        }

        return null
      }),
    )
  }

  override(exact: Route, response: () => Observable<object>): void {
    const original = this.apiRequest.bind(this)

    this.apiRequest = (route: Route): Observable<object> => {
      if (isEqual(route, exact)) {
        return response()
      } else {
        return original(route)
      }
    }
  }

  overrideMatching<Value>(matcher: CasePath<Route, Value>, response: (value: Value) => Observable<object>): void {
    const original = this.apiRequest.bind(this)

    this.apiRequest = function apiRequest(route: Route): Observable<object> {
      const extractionResult = matcher.extract(route)

      if (extractionResult) {
        return response(extractionResult.value)
      } else {
        return original(route)
      }
    }
  }
}
