<ion-content [fullscreen]="true">
  <sir-header/>

  <div id="container" class="page-content mb-5">
    <div class="d-flex flex-column justify-content-center upper-part">
      <p class="primary-color-b3 upper-part__number">
        404
      </p>

      <p class="upper-part__text">
        Pagina non trovata
      </p>
    </div>

    <div class="central-text">
      <p class="text-center central-text__upper-part">
        Siamo spiacenti, ma la pagina che stai cercando non è disponibile.<br>
        La pagina potrebbe essere stata rimossa o spostata.
      </p>

      <p class="text-center central-text">
        Torna alla pagina principale per trovare le informazioni che cerchi.
      </p>
    </div>

    <div class="d-flex ion-justify-content-center mt-5">
      <button itButton="primary" class="ms-2 button" size="lg" type="button">
        <a [href]="environment.main_page_url" class="button-ref">Torna alla pagina principale</a>
      </button>
    </div>
  </div>

  <sir-footer/>
</ion-content>
