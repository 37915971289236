export interface BreadcrumbItem {
  label: string;
  url: string | null;
}

type BreadcrumbKey = 'home' | 'licenses' | 'questionnaires' | 'users'
type BreadcrumbList = Record<BreadcrumbKey, BreadcrumbItem>

export const breadcrumbList: BreadcrumbList = {
  home: {
    label: 'Home',
    url: '/home',
  },
  licenses: {
    label: 'Autorizzazioni',
    url: '/licenses',
  },
  questionnaires: {
    label: 'Schede',
    url: '/questionnaires',
  },
  users: {
    label: 'Gestione utenti',
    url: '/administration/users',
  },
}
