import { inject } from '@angular/core'
import { CanActivateFn } from '@angular/router'
import { catchError, map, of } from 'rxjs'
import { z } from 'zod'
import { SessionClient } from '../../session-client/session-client'
import { Schede } from '../../shared-models/server-route/schede'
import { ApiClient } from '@api-client'
import { Route } from '@shared-models'

export const canCompleteQuestionnaire = (): CanActivateFn => (route) => {
  const apiClient = inject(ApiClient)
  const activeUser = inject(SessionClient).activeUser()

  const schedaId = route.params['schedaId']

  if (activeUser) {
    return apiClient.decodedApiRequest(
      Route.schede(Schede.questionnaireEditors({ id: schedaId })),
      z.object({
        data: z.object({
          autorizzazione: z.object({
            titolare: z.object({
              operatori: z.object({
                id_account: z.object({
                  user: z.string(),
                }),
              }).array(),
            }),
          }),
        }),
      }),
    ).pipe(
      map((response) =>
        response.data.autorizzazione.titolare.operatori
          .map((operatore) => operatore.id_account.user)
          .includes(activeUser.id),
      ),
      catchError((err) => {
        console.log(err)
        return of(false)
      }),
    )
  }

  return of(false)
}
