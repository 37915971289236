import { Data, OptionToRemove, StorageClient } from './storage-client'

export class StorageClientLive extends StorageClient {
  override getData(key: string): Promise<Data> {
    return new Promise((resolve, reject) => {
      const item = localStorage.getItem(key)

      if (item) {
        resolve({ value: item })
      } else {
        reject('No value has been found for that key')
      }
    })
  }

  override setData(key: string, value: string): Promise<void> {
    return new Promise((resolve, reject) => {
      try {
        localStorage.setItem(key, value)
        resolve()
      }
      catch (err) {
        reject(err)
      }
    })
  }

  override remove(options: OptionToRemove): Promise<void> {
    return new Promise((resolve, reject) => {
      try {
        localStorage.removeItem(options.key)
        resolve()
      }
      catch (err) {
        reject(err)
      }
    })
  }

  override clear(): Promise<void> {
    return new Promise((resolve, reject) => {
      try {
        localStorage.clear()
        resolve()
      }
      catch (err) {
        reject(err)
      }
    })
  }
}
