export interface Data {
  value: string | null
}

export interface OptionToRemove {
  key: string;
}

export abstract class StorageClient {
  static readonly userToken = 'user-token'

  abstract getData(key: string): Promise<Data>

  abstract setData(key: string, value: string): Promise<void>

  abstract remove(options: OptionToRemove): Promise<void>

  abstract clear(): Promise<void>
}
