import { inject } from '@angular/core'
import { CanActivateFn } from '@angular/router'
import { SessionClient } from '../../session-client/session-client'
import { UserRoleNames } from '../../shared-models/user'
export const hasRole = (...roles: [UserRoleNames, ...UserRoleNames[]]): CanActivateFn => () => {
  const sessionClient = inject(SessionClient)

  const role = sessionClient.activeUser()?.roleName
  return !!role && roles.includes(role)
}
