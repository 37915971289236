import { DatePipe } from '@angular/common'
import { Component } from '@angular/core'
import { IonContent } from '@ionic/angular/standalone'
import { ItButtonDirective, ItSpinnerComponent } from 'design-angular-kit'
import { environment } from '../environments/environment'
import { FooterComponent } from '../footer-component/footer.component'
import { HeaderComponent } from '../header-component/header.component'
import { PageContentIntroComponent } from '../page-content-intro-component/page-content-intro.component'
import {
  QuestionnairesCrupdateComponent,
} from '../questionnaires-completion-page/questionnaires-crupdate/questionnaires-crupdate.component'
@Component({
  templateUrl: './not-found.page.html',
  styleUrls: ['./not-found.page.scss'],
  standalone: true,
  imports: [
    IonContent,
    HeaderComponent,
    FooterComponent,
    DatePipe,
    ItSpinnerComponent,
    PageContentIntroComponent,
    QuestionnairesCrupdateComponent,
    ItButtonDirective,
  ],
})
export class NotFoundPage {
  protected readonly environment = environment
}
