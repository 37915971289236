import { HttpClient } from '@angular/common/http'
import { inject } from '@angular/core'
import { Observable } from 'rxjs'
import { ApiClient } from './interface'
import { Route } from '@shared-models'

export class ApiClientLive extends ApiClient {
  private readonly httpClient = inject(HttpClient)

  override apiRequest(route: Route): Observable<object> {
    const httpRequest = route.print().toAngular()

    return this.httpClient.request(httpRequest.method, httpRequest.url, {
      body: httpRequest.body,
      headers: httpRequest.headers,
      params: httpRequest.params,
    })
  }
}
