import { Case, makeEnum } from '@technicated/ts-enums'
import { AdditionalSchedaFieldsForPatch } from '../../questionnaires-completion-page/questionnaires-crupdate/helper'
import { HttpRequest, SchedaSchema } from '@shared-models'

class StructuresProto {
  print(this: Schede, request: HttpRequest): HttpRequest {
    switch (this.case) {
      case 'questionnairesList':
        return request.appendingPathComponent('items/scheda')
          .withQueryParam('filter[autorizzazione][servizio][struttura][id][_eq]', this.p.id)
          .withQueryParam('fields[]',
            '*,rilevazione.*,' +
            'autorizzazione.*,' +
            'autorizzazione.servizio.*,' +
            'autorizzazione.titolare.*,' +
            'autorizzazione.titolare.codice_gestione.*,' +
            'autorizzazione.titolare.operatori.id_account.user,' +
            'autorizzazione.titolare.natura_giuridica.*,' +
            'autorizzazione.servizio.struttura.*,' +
            'autorizzazione.servizio.struttura.comune.nome,' +
            'autorizzazione.servizio.struttura.comune.pv.sigla,' +
            'autorizzazione.servizio.tipologia_servizio.*,' +
            'iscritti_stranieri.tot_iscritti,' +
            'iscritti_stranieri.tot_femmine,' +
            'iscritti_stranieri.id_stato_estero.*,' +
            'condizioni_retta.id_altre_condizioni.*',
          )

      case 'questionnaireEditors':
        return request.appendingPathComponent(`items/scheda/${ this.p.id }`)
          .withQueryParam('fields[]', 'autorizzazione.titolare.operatori.id_account.user')

      case 'statiEsteri':
        return request.appendingPathComponent('items/stato_estero')
          .withQueryParam('filter[id][_nin]', `${this.p.countriesToFilter}`)
          .withQueryParam('fields[]', '*  ,scheda.id, stato_estero.*')
          .withQueryParam('limit', '-1')

      case 'altreCondizioniRetta':
        return request.appendingPathComponent('items/elenco_altre_condizioni_retta')

      case 'questionnaireDetails':
        return request.appendingPathComponent('items/scheda/' + this.p.id)
          // .withQueryParam('fields[]', '*,rilevazione.*,autorizzazione.servizio.tipologia_servizio.*,iscritti_stranieri.tot_iscritti,iscritti_stranieri.tot_femmine,iscritti_stranieri.id_stato_estero.*,condizioni_retta.id_altre_condizioni.*')
          .withQueryParam('fields[]',
            '*,rilevazione.*,' +
            'autorizzazione.*,' +
            'autorizzazione.servizio.*,' +
            'autorizzazione.titolare.*,' +
            'autorizzazione.titolare.codice_gestione.*,' +
            'autorizzazione.titolare.natura_giuridica.*,' +
            'autorizzazione.titolare.operatori.id_account.user,' +
            'autorizzazione.servizio.struttura.*,' +
            'autorizzazione.servizio.struttura.comune.nome,' +
            'autorizzazione.servizio.struttura.comune.pv.sigla,' +
            'autorizzazione.servizio.tipologia_servizio.*,' +
            'iscritti_stranieri.tot_iscritti,' +
            'iscritti_stranieri.tot_femmine,' +
            'iscritti_stranieri.id_stato_estero.*,' +
            'condizioni_retta.id_altre_condizioni.*',
          )

      case 'saveQuestionnaire':
        return request.appendingPathComponent('items/scheda/' + this.p.id)
          .withQueryParam('fields[]', '*,rilevazione.*,autorizzazione.servizio.tipologia_servizio.*,iscritti_stranieri.tot_iscritti,iscritti_stranieri.tot_femmine,iscritti_stranieri.id_stato_estero.*,condizioni_retta.id_altre_condizioni.*')
          .withBody({ ...this.p.data })
          .withMethod('PATCH')

      case 'confirmQuestionnaire':
        return request.appendingPathComponent('items/scheda/' + this.p.id)
          .withQueryParam('fields[]', '*,rilevazione.*,autorizzazione.servizio.tipologia_servizio.*,iscritti_stranieri.tot_iscritti,iscritti_stranieri.tot_femmine,iscritti_stranieri.id_stato_estero.*,condizioni_retta.id_altre_condizioni.*')
          .withBody({ ...this.p.data, bozza: false })
          .withMethod('PATCH')
    }
  }
}

export type Schede = StructuresProto & (
  | Case<'questionnairesList', { id: string }>
  | Case<'questionnaireEditors', { id: string }>
  | Case<'statiEsteri', { countriesToFilter: number[] }>
  | Case<'altreCondizioniRetta'>
  | Case<'questionnaireDetails', { id: string }>
  | Case<'saveQuestionnaire', { data: Partial<Omit<SchedaSchema, 'condizioni_retta' | 'iscritti_stranieri'> & AdditionalSchedaFieldsForPatch>, id: string }>
  | Case<'confirmQuestionnaire', { data: Partial<Omit<SchedaSchema, 'condizioni_retta' | 'iscritti_stranieri'> & AdditionalSchedaFieldsForPatch>, id: string }>
  )

export const Schede = makeEnum<Schede>({ proto: StructuresProto })
