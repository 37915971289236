import { Case, makeEnum } from '@technicated/ts-enums'
import { UserFormValues } from '../../user-crupdate-component/user-crupdate.component'
import { HttpRequest } from '@shared-models'

class UsersProto {
  print(this: Users, request: HttpRequest): HttpRequest {
    let requestToSend
    let requestData
    switch (this.case) {
      case 'createUser':
        requestData = {
          codice_fiscale: this.p.data.taxCode,
          numero_telefono: this.p.data.telephone,
          user: {
            first_name: this.p.data.name,
            last_name: this.p.data.surname,
            email: this.p.data.email,
            role: this.p.data.roleId,
            external_identifier: this.p.data.taxCode,
            provider: 'loginumbria',
          },
          comuni: this.p.data.connectedMunicipalities
            ? this.p.data.connectedMunicipalities.map((municipality) => ({ id_comune: municipality }))
            : [],
          titolari: this.p.data.connectedLicenseHolders
            ? this.p.data.connectedLicenseHolders.map((licenseHolder) => ({ id_titolare: licenseHolder }))
            : [],
          status: 'published',
        }

        return request.appendingPathComponent('items/account')
          .withMethod('POST')
          .withBody(requestData)

      case 'enableOrDisableUser':
        if (this.p.enableUser) {
          requestData = {
            user: {
              id: this.p.userId,
              status: 'active',
            },
          }
        } else {
          requestData = {
            user: {
              id: this.p.userId,
              status: 'suspended',
            },
          }
        }

        return request.appendingPathComponent(`/items/account/${this.p.id}`)
          .withMethod('PATCH')
          .withBody(requestData)

      case 'licenseHolderUsersList':
        requestToSend = request.appendingPathComponent('/items/account')
          .withQueryParam(
            'fields[]',
            '*,' +
            'user.id,user.first_name,user.last_name,user.email,user.role.id,user.role.name,user.status,' +
            'comuni.id_comune.id,comuni.id_comune.nome,' +
            'titolari.id_titolare.id,titolari.id_titolare.denominazione',
          )
          .withQueryParam('filter[user][role][name][_eq]', 'Titolare')
          .withQueryParam('page', this.p.page.toString())
          .withQueryParam('limit', this.p.limit.toString())
          .withQueryParam('meta', 'filter_count')

        if (this.p.licenseHolderId) {
          requestToSend = requestToSend.withQueryParam('filter[titolari][id_titolare][id][_eq]', this.p.licenseHolderId.toString())
        }

        if (this.p.nameOrSurnameSubstring !== undefined) {
          requestToSend = appendNameOrSurnameSubstringRelatedQueryParams(this.p.nameOrSurnameSubstring, requestToSend)
        }

        return requestToSend
      case 'municipalityUsersList':
        requestToSend = request.appendingPathComponent('/items/account')
          .withQueryParam(
            'fields[]',
            '*,' +
            'user.id,user.first_name,user.last_name,user.email,user.role.id,user.role.name,user.status,' +
            'comuni.id_comune.id,comuni.id_comune.nome,' +
            'titolari.id_titolare.id,titolari.id_titolare.denominazione',
          )
          .withQueryParam('filter[user][role][name][_eq]', 'Comune')
          .withQueryParam('page', this.p.page.toString())
          .withQueryParam('limit', this.p.limit.toString())
          .withQueryParam('meta', 'filter_count')

        if (this.p.municipalityId) {
          requestToSend = requestToSend.withQueryParam('filter[comuni][id_comune][id][_eq]', this.p.municipalityId.toString())
        }

        if (this.p.nameOrSurnameSubstring !== undefined) {
          requestToSend = appendNameOrSurnameSubstringRelatedQueryParams(this.p.nameOrSurnameSubstring, requestToSend)
        }

        return requestToSend
      case 'regionUsersList':
        requestToSend = request.appendingPathComponent('/items/account')
          .withQueryParam(
            'fields[]',
            '*,' +
            'user.id,user.first_name,user.last_name,user.email,user.role.id,user.role.name,user.status,' +
            'comuni.id_comune.id,comuni.id_comune.nome,' +
            'titolari.id_titolare.id,titolari.id_titolare.denominazione',
          )
          .withQueryParam('filter[user][role][name][_eq]', 'Regione')
          .withQueryParam('page', this.p.page.toString())
          .withQueryParam('limit', this.p.limit.toString())
          .withQueryParam('meta', 'filter_count')

        if (this.p.nameOrSurnameSubstring !== undefined) {
          requestToSend = appendNameOrSurnameSubstringRelatedQueryParams(this.p.nameOrSurnameSubstring, requestToSend)
        }

        return requestToSend

      case 'roles':
        return request.appendingPathComponent('/roles')
          .withQueryParam('fields', 'id,name')

      case 'searchForEmail':
        return request.appendingPathComponent('items/account')
          .withQueryParam('filter[user][email][_eq]', this.p.email)

      case 'searchForTaxCode':
        return request.appendingPathComponent('items/account')
          .withQueryParam('filter[codice_fiscale][_eq]', this.p.taxCode)

      case 'updateUser':
        requestData = {
          numero_telefono: this.p.data.telephone,
          user: {
            id: this.p.userId,
            first_name: this.p.data.name,
            last_name: this.p.data.surname,
            email: this.p.data.email,
          },
          comuni: this.p.data.connectedMunicipalities
            ? this.p.data.connectedMunicipalities.map((municipality) => ({ id_comune: municipality }))
            : [],
          titolari: this.p.data.connectedLicenseHolders
            ? this.p.data.connectedLicenseHolders.map((licenseHolder) => ({ id_titolare: licenseHolder }))
            : [],
        }

        return request.appendingPathComponent(`items/account/${this.p.id}`)
          .withMethod('PATCH')
          .withBody(requestData)

      case 'user':
        requestToSend = request.appendingPathComponent(`/items/account/${this.p.id}`)
          .withQueryParam(
            'fields',
            '*,' +
            'user.id,user.first_name,user.last_name,user.email,user.role.id,user.role.name,user.status,' +
            'comuni.id_comune.id,comuni.id_comune.nome,' +
            'titolari.id_titolare.id,titolari.id_titolare.denominazione',
          )

        return requestToSend
    }
  }
}

export type Users = UsersProto & (
  | Case<'createUser', { data: UserFormValues }>
  | Case<'enableOrDisableUser', { id: string, userId: string, enableUser: boolean }>
  | Case<'licenseHolderUsersList', { page: number, limit: number, nameOrSurnameSubstring?: string, licenseHolderId?: number}>
  | Case<'municipalityUsersList', { page: number, limit: number, nameOrSurnameSubstring?: string, municipalityId?: number}>
  | Case<'regionUsersList', { page: number, limit: number, nameOrSurnameSubstring?: string}>
  | Case<'roles'>
  | Case<'searchForEmail', { email: string }>
  | Case<'searchForTaxCode', { taxCode: string }>
  | Case<'updateUser', { id: string, userId: string, data: UserFormValues }>
  | Case<'user', { id: string }>
  )

export const Users = makeEnum<Users>({ proto: UsersProto })

const appendNameOrSurnameSubstringRelatedQueryParams = (nameOrSurnameSubstring: string, requestToSend:HttpRequest): HttpRequest => {
  const nameOrSurnameParts = nameOrSurnameSubstring.split(' ')

  let i = 0

  for (const part of nameOrSurnameParts) {
    requestToSend = requestToSend.withQueryParam(`filter[_or][${i}][user][first_name][_icontains]`, part)
      .withQueryParam(`filter[_or][${i + 1}][user][last_name][_icontains]`, part)

    i += 2
  }

  return requestToSend
}
