import { Routes } from '@angular/router'
import { NotFoundPage } from '../not-found-page/not-found.page'
import { AuthGuard } from '../utils/guards/auth.guard'
import { canCompleteQuestionnaire } from '../utils/guards/questionnaire-completion.guard'
import { hasRole } from '../utils/guards/role.guard'
import { SessionGuard } from '../utils/guards/session.guard'

export const routes: Routes = [
  {
    canActivate: [SessionGuard],
    path: 'login',
    loadComponent: () => import('../login-page/login.page').then(m => m.LoginPage),
  },
  {
    path: 'administration',
    canActivateChild: [AuthGuard],
    children: [
      {
        path: 'surveys',
        canActivate: [hasRole('Administrator', 'Regione')],
        loadComponent: () => import('../surveys-page/surveys.page').then(m => m.SurveysPage),
      },
      {
        path: 'monitoraggi',
        canActivateChild: [hasRole('Administrator', 'Regione')],
        loadComponent: () => import('../monitoraggi-regione-page/monitoraggi-regione.page').then(m => m.MonitoraggiRegionePage),
      },
      {
        path: 'programmazione',
        canActivateChild: [hasRole('Administrator', 'Regione')],
        children: [
          {
            path: ':id',
            loadComponent: () => import('../programmazione-details-page/programmazione-details.page').then(m => m.ProgrammazioneDetailsPage),
          },
          {
            path: '',
            loadComponent: () => import('../programmazioni-page/programmazioni.page').then(m => m.ProgrammazioniPage),
          },
        ],
      },
      {
        path: 'users',
        canActivateChild: [hasRole('Administrator', 'Comune', 'Regione')],
        children: [
          {
            path: 'create',
            loadComponent: () => import('../user-creation-page/user-creation.page').then(m => m.UserCreationPage),
          },
          {
            path: 'update/:id',
            loadComponent: () => import('../user-update-page/user-update.page').then(m => m.UserUpdatePage),
          },
          {
            path: ':id',
            loadComponent: () => import('../user-details-page/user-details.page').then(m => m.UserDetailsPage),
          },
          {
            path: '',
            loadComponent: () => import('../users-page/users.page').then(m => m.UsersPage),
          },
        ],
      },
    ],
  },
  {
    path: 'fondi',
    canActivateChild: [AuthGuard, hasRole('Administrator', 'Comune')],
    children: [
      {
        path: 'monitoraggio',
        children: [
          {
            path: ':id',
            loadComponent: () => import('../monitoraggio-page/monitoraggio.page').then(m => m.MonitoraggioPage),
          },
          {
            path: '',
            loadComponent: () => import('../monitoraggi-page/monitoraggi.page').then(m => m.MonitoraggiPage),
          },
        ],
      },
      {
        path: 'programmazione',
        children: [
          {
            path: ':id',
            loadComponent: () => import('../programmazione-comune-details-page/programmazione-comune-details.page').then(m => m.ProgrammazioneComuneDetailsPage),
          },
          {
            path: '',
            loadComponent: () => import('../programmazioni-comune-page/programmazioni-comune.page').then(m => m.ProgrammazioniComunePage),
          },
        ],
      },
    ],
  },
  {
    path: 'licenses',
    canActivateChild: [AuthGuard],
    children: [
      {
        path: '',
        loadComponent: () => import('../licenses-page/licenses.page').then(m => m.LicensesPage),
      },
      {
        path: 'structure/:id',
        loadComponent: () => import('../structure-details-page/structure-details.page').then(m => m.StructureDetailsPage),
      },
      {
        path: 'license-registration',
        canActivate: [hasRole('Administrator', 'Comune')],
        loadComponent: () => import('../license-registration-page/license-registration.page').then(m => m.LicenseRegistrationPage),
      },
      {
        path: 'license-renewal',
        canActivate: [hasRole('Administrator', 'Comune')],
        loadComponent: () => import('../license-renewal-page/license-renewal.page').then(m => m.LicenseRenewalPage),
      },
    ],
  },
  {
    path: 'questionnaires',
    canActivateChild: [AuthGuard],
    children: [
      {
        path: '',
        loadComponent: () => import('../questionnaires-page/questionnaires.page').then(m => m.QuestionnairesPage),
      },
      {
        path: 'structure/:id',
        children: [
          {
            path: '',
            loadComponent: () => import('../questionnaires-details-page/questionnaires-details-page').then(m => m.QuestionnairesDetailsPage),
          },
          {
            path: 'completion/:schedaId',
            canActivate: [canCompleteQuestionnaire()],
            loadComponent: () => import('../questionnaires-completion-page/questionnaires-completion.page').then(m => m.QuestionnairesCompletionPage),
          },
        ],
      },
    ],
  },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: '**',
    component: NotFoundPage,
  },
]
