import { inject } from '@angular/core'
import { CanActivateFn, Router, UrlTree } from '@angular/router'
import { SessionClient } from '../../session-client/session-client'

export const SessionGuard: CanActivateFn = (_): UrlTree | boolean => {
  const sc = inject(SessionClient)
  const router = inject(Router)

  return sc.activeUser() ? router.createUrlTree(['licenses']) : true
}
