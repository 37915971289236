import { of } from 'rxjs'
import { ApiClient } from './interface'
import {
  Route,
  StrutturaSchemaFromStruttureCEWithSchede,
} from '@shared-models'

declare module './interface' {
  export interface ApiClient {
    mockStructuresWithQuestionnaireParsedData(): void
  }
}

ApiClient.prototype.mockStructuresWithQuestionnaireParsedData = function mockStructuresWithQuestionnaireParsedData(this: ApiClient): void {
  this.overrideMatching(
    Route('structuresWithQuestionnaire'),
    ({ page, limit, text }) => {
      const structures: StrutturaSchemaFromStruttureCEWithSchede[] = [
        {
          id: 1,
          denominazione: 'Il Grillo Parlante',
          comune: {
            nome: 'Allerona',
          },
          schede: [
            {
              id: 1,
              tipologia_servizio: 'Nido d\'infanzia',
              bozza: false,
            },
            {
              id: 2,
              tipologia_servizio: 'Centro bambini e bambine',
              bozza: true,
            },
            {
              id: 3,
              tipologia_servizio: 'Spazio gioco',
              bozza: false,
            },
          ],
        },
        {
          id: 2,
          denominazione: 'Girandole a Colori',
          comune: {
            nome: 'Amelia',
          },
          schede: [
            {
              id: 6,
              tipologia_servizio: 'Nido d\'infanzia',
              bozza: false,
            },
            {
              id: 7,
              tipologia_servizio: 'Spazio gioco',
              bozza: true,
            },
          ],
        },
        {
          id: 3,
          denominazione: 'La Coccinella',
          comune: {
            nome: 'Amelia',
          },
          schede: [
            {
              id: 8,
              tipologia_servizio: 'Nido d’infanzia',
              bozza: true,
            },
            {
              id: 9,
              tipologia_servizio: 'Centro bambini e bambine',
              bozza: true,
            },
            {
              id: 10,
              tipologia_servizio: 'Spazio gioco',
              bozza: false,
            },
          ],
        },
        {
          id: 4,
          denominazione: 'Latte e biscotti',
          comune: {
            nome: 'Gubbio',
          },
          schede: [
            {
              id: 11,
              tipologia_servizio: 'Nido d’infanzia',
              bozza: false,
            },
            {
              id: 12,
              tipologia_servizio: 'Centro bambini e bambine',
              bozza: false,
            },
          ],
        },
        {
          id: 5,
          denominazione: 'La Cuccumella',
          comune: {
            nome: 'Narni',
          },
          schede: [
            {
              id: 18,
              tipologia_servizio: 'Nido d’infanzia',
              bozza: true,
            },
            {
              id: 29,
              tipologia_servizio: 'Centro bambini e bambine',
              bozza: false,
            },
            {
              id: 31,
              tipologia_servizio: 'Spazio gioco',
              bozza: true,
            },
          ],
        },
        {
          id: 6,
          denominazione: 'Luci e ombre',
          comune: {
            nome: 'Perugia',
          },
          schede: [
            {
              id: 111,
              tipologia_servizio: 'Nido d’infanzia',
              bozza: true,
            },
            {
              id: 112,
              tipologia_servizio: 'Centro bambini e bambine',
              bozza: true,
            },
          ],
        },
        {
          id: 7,
          denominazione: 'La Ciaramicola',
          comune: {
            nome: 'Perugia',
          },
          schede: [
            {
              id: 181,
              tipologia_servizio: 'Nido d’infanzia',
              bozza: false,
            },
            {
              id: 291,
              tipologia_servizio: 'Centro bambini e bambine',
              bozza: false,
            },
            {
              id: 312,
              tipologia_servizio: 'Spazio gioco',
              bozza: false,
            },
          ],
        },
        {
          id: 8,
          denominazione: 'Il Grullo Sognante',
          comune: {
            nome: 'Campello sul Clitunno',
          },
          schede: [
            {
              id: 61,
              tipologia_servizio: 'Nido d\'infanzia',
              bozza: false,
            },
          ],
        },
        {
          id: 9,
          denominazione: 'Ghirlande di fiori',
          comune: {
            nome: 'Amelia',
          },
          schede: [
            {
              id: 116,
              tipologia_servizio: 'Nido d\'infanzia',
              bozza: false,
            },
            {
              id: 117,
              tipologia_servizio: 'Spazio gioco',
              bozza: false,
            },
          ],
        },
        {
          id: 10,
          denominazione: 'Colori colorati',
          comune: {
            nome: 'Amelia',
          },
          schede: [
            {
              id: 118,
              tipologia_servizio: 'Centro bambini e bambine',
              bozza: true,
            },
            {
              id: 119,
              tipologia_servizio: 'Spazio gioco',
              bozza: false,
            },
          ],
        },
      ]

      const offset = page * limit

      let filteredStructures = structures

      if (text) {
        filteredStructures = filteredStructures.filter((str) => str.denominazione.toLowerCase().includes(text.toLowerCase()))
      }

      return of({
        meta:{
          total_count: structures.length,
          filter_count: filteredStructures.length,
        },
        data: filteredStructures.splice(offset, limit),
      })
    },
  )
}
