import { NgOptimizedImage } from '@angular/common'
import { Component } from '@angular/core'

@Component({
  selector: 'sir-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  standalone: true,
  imports: [
    NgOptimizedImage,
  ],
})
export class FooterComponent {}
