<footer class="it-footer">
  <div class="it-footer-main neutral-2-bg-b6 pb-5">
    <div class="container">
      <section>
        <div class="row clearfix">
          <div class="col-sm-12 d-flex justify-content-between">
            <div class="it-brand-wrapper">
              <a href="#" data-focus-mouse="false">
                <img class="img-fluid" ngSrc="../assets/SIRSE_logo.png" alt="logo SIRSE" height="70" width="70">

                <div class="it-brand-text ion-margin-start">
                  <div class="it-brand-title h2">SIRSE</div>

                  <div class="it-brand-tagline d-none d-md-block">Servizio Informativo Regionale dei Servizi Socio-Educativi</div>
                </div>
              </a>
            </div>

            <div class="it-brand-wrapper">
              <img ngSrc="../assets/region-logo-footer.png" alt="logo SIRSE" height="69" width="141">
            </div>
          </div>
        </div>

        <div class="row mb-5">
          <h4 class="border-bottom ps-0 pb-3">
            <a>
              Contatti
            </a>
          </h4>

          <div class="col-12 col-md-5 ps-0">
            <p>
              <strong>Regione Umbria</strong><br>
              Servizio Istruzione, Università, Diritto allo studio e ricerca<br>
              Via Mario Angeloni n. 61<br>
              06124 Perugia
            </p>
          </div>

          <div class="col-12 col-md-5">
            <p>
              Email: <a href="mailto:zerosei@regione.umbria.it">zerosei&#64;regione.umbria.it</a><br>
              PEC: <a href="mailto:direzionesviluppo.regione@postacert.umbria.it">direzionesviluppo.regione&#64;postacert.umbria.it</a><br>
              Telefono: <a href="callto:0755045286">075 504 52 86</a> - <a href="callto:075 504 54 01">075 504 54 01</a>
            </p>
          </div>
        </div>
      </section>

      <section class="p-2">
        <p class="border-top ms-0 pt-3">Creato da Weedea s.r.l</p>
      </section>
    </div>
  </div>
</footer>
