import { Case, makeEnum } from '@technicated/ts-enums'
import { HttpRequest } from '@shared-models'

class StructuresProto {
  print(this: Structures, request: HttpRequest): HttpRequest {
    let requestToSend
    switch (this.case) {
      case 'list':
        let finalRequest = request.appendingPathComponent('strutture')
          .withQueryParam('page', (this.p.page + 1).toString())
          .withQueryParam('limit', this.p.limit.toString())

        if (this.p.status) {
          finalRequest = finalRequest.withQueryParam('status', this.p.status.toString())
        }

        if (this.p.name) {
          finalRequest = finalRequest.withQueryParam('name', this.p.name.toString())
        }

        return finalRequest

      case 'names':
        requestToSend = request.appendingPathComponent('items/struttura')
          .withQueryParam('fields[]', 'denominazione,id')
          .withQueryParam('limit', '-1')

        if (this.p.substring) {
          request.withQueryParam('filter[denominazione][_icontain]', this.p.substring.toString())
        }

        return requestToSend

      case 'structure':
        requestToSend = request.appendingPathComponent(`items/struttura/${this.p.id}`)
          .withQueryParam('fields[]',
            '*,' +
            'comune.*,' +
            'comune.pv.*,' +
            'servizio_struttura.*,' +
            'servizio_struttura.tipologia_servizio.*',
          )

        return requestToSend

      case 'structureServiceLicenses':
        return request.appendingPathComponent('items/autorizzazione_servizio')
          .withQueryParam('limit', '-1')
          .withQueryParam('filter[servizio][_eq]', this.p.serviceId.toString())
          .withQueryParam('fields[]',
            '*,' +
            'titolare.*,' +
            'titolare.natura_giuridica.*,' +
            'titolare.codice_gestione.id,' +
            'titolare.codice_gestione.descrizione,' +
            'titolare.operatori.id_account.user',
          )
          .withQueryParam('sort[]', '-data_scadenza')

      case 'structureServices':
        return request.appendingPathComponent('items/servizio_struttura')
          .withQueryParam('limit', '-1')
          .withQueryParam('filter[struttura][_eq]', this.p.structureId.toString())
          .withQueryParam('fields[]', '*,tipologia_servizio.*')

      case 'structureServiceTypes':
        return request.appendingPathComponent('items/tipologia_servizio')
          .withQueryParam('limit', '-1')
    }
  }
}

export type Structures = StructuresProto & (
  | Case<'list', { page: number, limit: number, status?: string, name?: string }>
  | Case<'names', { substring?: string }>
  | Case<'structure', { id: string }>
  | Case<'structureServiceLicenses', { serviceId: number }>
  | Case<'structureServices', { structureId: string }>
  | Case<'structureServiceTypes'>
  )

export const Structures = makeEnum<Structures>({ proto: StructuresProto })
