import { z } from 'zod'

//todo rivedere schemi "base" e schemi rifattorizzabili in altri file

export const BaseComuneSchema = z.object({
  id: z.number(),
  nome: z.string(),
})

export type BaseComuneSchema = z.infer<typeof BaseComuneSchema>

export const BaseStrutturaSchema = z.object({
  id: z.number(),
  denominazione: z.string(),
  comune: BaseComuneSchema,
})

export const BaseStrutturaSchemaSchede = z.object({
  id: z.number(),
  denominazione: z.string(),
  comune: z.object({
    nome: z.string(),
  }),
})

const ProvinciaSchema = z.object({
  id: z.number(),
  nome: z.string(),
  sigla: z.string(),
})

export const ComuneSchema = BaseComuneSchema.merge(
  z.object({
    nome: z.string(),
    codice_istat: z.string(),
    pv: ProvinciaSchema,
  }),
)

export const TipologiaServizioSchema = z.object({
  id: z.number(),
  fascia_eta: z.string().nullable(),
  denominazione: z.string(),
})

export const CodiceGestioneSchema = z.object({
  id: z.number(),
  descrizione: z.string(),
})

export const TitolareSchema = z.object({
  cf_legale_rappresentante: z.string().nullable(),
  comune_sede_legale: z.string(),
  denominazione: z.string(),
  email: z.string(),
  id: z.number(),
  indirizzo_sede_legale: z.string(),
  legale_rappresentante: z.string(),
  partita_iva_cf: z.string().nullable(),
  pec: z.string().nullable(),
  sito_web: z.union([z.string(), z.null()]),
  telefono: z.string(),
  natura_giuridica: z.object({
    denominazione: z.string(),
    id: z.number(),
    titolare_pubblico: z.boolean(),
  }),
  codice_gestione: CodiceGestioneSchema,
  operatori: z.object({
    id_account: z.object({
      user: z.string(),
    }),
  }).array(),
})

export type TitolareSchema = z.infer<typeof TitolareSchema>

export const BaseAutorizzazioneSchema = z.object({
  archiviata: z.boolean(),
  capacita_ricettiva: z.number(),
  cucina_interna: z.boolean(),
  data_atto_revoca: z.union([z.string(), z.null()]),
  data_atto_rilascio: z.string(),
  data_atto_sospensione: z.union([z.string(), z.null()]),
  data_fine_sospensione: z.union([z.string(), z.null()]),
  data_inizio_revoca: z.union([z.string(), z.null()]),
  data_inizio_sospensione: z.union([z.string(), z.null()]),
  data_inizio_validita: z.string(),
  data_scadenza: z.string(),
  date_created: z.string(),
  id: z.number(),
  locali_gestione_pasti: z.boolean(),
  motivazione_revoca: z.union([z.string(), z.null()]),
  motivazione_sospensione: z.union([z.string(), z.null()]),
  numero_atto: z.string(),
  numero_atto_revoca: z.union([z.string(), z.null()]),
  numero_atto_sospensione: z.union([z.string(), z.null()]),
  rimpiazza: z.union([z.number(), z.null()]),
  servizio: z.number(),
  superficie_utile_netta: z.number(),
  rimpiazzata_da: z.number().array(),
  superficie_spazi_esterni: z.number().nullable(),
  superficie_spazi_interni: z.number().nullable(),
  spazi_attivita_ordinaria: z.number().nullable(),
  spazi_attivita_comuni: z.number().nullable(),
  spazi_consumo_pasto: z.boolean().nullable(),
  laboratori_atelier: z.boolean().nullable(),
  spazi_riposo: z.boolean().nullable(),
  giardino: z.boolean().nullable(),

})

export type BaseAutorizzazioneSchema = z.infer<typeof BaseAutorizzazioneSchema>

export const AutorizzazioneSchemaWithServizio = BaseAutorizzazioneSchema.merge(z.object({
  servizio: z.object({
    id: z.number(),
    struttura: z.object({
      id: z.number(),
      denominazione: z.string(),
    }),
    tipologia_servizio: TipologiaServizioSchema,
  }),
}))

export type AutorizzazioneSchemaWithServizio = z.infer<typeof AutorizzazioneSchemaWithServizio>

export const AutorizzazioneSchemaWithTitolare = BaseAutorizzazioneSchema.merge(z.object({
  titolare: TitolareSchema,
}))

export type AutorizzazioneSchemaWithTitolare = z.infer<typeof AutorizzazioneSchemaWithTitolare>

export const StrutturaSchema = z.object({
  id: z.number(),
  denominazione: z.string(),
  indirizzo: z.string(),
  email: z.union([z.string(), z.null()]),
  sito_web: z.union([z.string(), z.null()]),
  telefono: z.string(),
  comune: ComuneSchema,
  cap: z.string(),
  servizio_struttura: z.object({
    id: z.number(),
    tipologia_servizio: TipologiaServizioSchema,
  }).array(),
})

export type StrutturaSchema = z.infer<typeof StrutturaSchema>

export const NaturaGiuridicaTitolareSchema = z.object({
  id: z.number(),
  titolare_pubblico: z.boolean(),
  denominazione: z.string(),
})

// --- custom endpoint references --------------------------------------------------------------------------------------

// --- custom endpoint for strutture with licences ---------------------------------------------------------------------

const AutorizzazioneSchemaFromStruttureCEWithLicenses = z.object({
  id: z.number(),
  servizio: z.object({
    tipologia_servizio: z.object({
      denominazione: z.string(),
    }),
  }),
  archiviata: z.boolean(),
  data_inizio_sospensione: z.union([z.string(), z.null()]),
  data_fine_sospensione: z.union([z.string(), z.null()]),
  numero_atto_revoca: z.union([z.string(), z.null()]),
  data_scadenza: z.string(),
})

export type AutorizzazioneSchemaFromStruttureCEWithLicenses = z.infer<typeof AutorizzazioneSchemaFromStruttureCEWithLicenses>

export const StrutturaSchemaFromStruttureCEWithLicenses =
  BaseStrutturaSchema.merge(
    z.object({
      autorizzazioni: z.array(AutorizzazioneSchemaFromStruttureCEWithLicenses),
    }),
  )

export type StrutturaSchemaFromStruttureCEWithLicenses = z.infer<typeof StrutturaSchemaFromStruttureCEWithLicenses>

// --- custom endpoint for strutture with questionnaires ---------------------------------------------------------------

const SchedaSchemaFromStruttureCEWithSchede = z.object({
  id: z.number(),
  tipologia_servizio: z.string(),
  bozza: z.boolean(),
  rilevazione: z.object({
    data_rilevazione: z.string(),
    data_inizio_rilevazione: z.string(),
    data_fine_rilevazione: z.string(),
  }),
})

export type SchedaSchemaFromStruttureCEWithSchede = z.infer<typeof SchedaSchemaFromStruttureCEWithSchede>

export const StrutturaSchemaFromStruttureCEWithSchede =
  BaseStrutturaSchemaSchede.merge(
    z.object({
      schede: SchedaSchemaFromStruttureCEWithSchede.array(),
    }),
  )

export type StrutturaSchemaFromStruttureCEWithSchede = z.infer<typeof StrutturaSchemaFromStruttureCEWithSchede>

export const StrutturaSchemaForQuestionnaire = z.object({
  id: z.number(),
  denominazione: z.string(),
  indirizzo: z.string(),
  email: z.union([z.string(), z.null()]),
  cap: z.string().nullable(),
  sito_web: z.union([z.string(), z.null()]),
  telefono: z.string(),
  comune: z.object({
    nome: z.string(),
    pv: z.object({
      sigla: z.string(),
    }),
  }),
})
export type StrutturaSchemaForQuestionnaire = z.infer<typeof StrutturaSchemaForQuestionnaire>

const AutorizzazioneSchemaForQuestionnaire = z.object({
  archiviata: z.boolean(),
  capacita_ricettiva: z.number(),
  cucina_interna: z.boolean(),
  data_atto_revoca: z.union([z.string(), z.null()]),
  data_atto_rilascio: z.string(),
  data_atto_sospensione: z.union([z.string(), z.null()]),
  data_fine_sospensione: z.union([z.string(), z.null()]),
  data_inizio_revoca: z.union([z.string(), z.null()]),
  data_inizio_sospensione: z.union([z.string(), z.null()]),
  data_inizio_validita: z.string(),
  data_scadenza: z.string(),
  date_created: z.string(),
  id: z.number(),
  locali_gestione_pasti: z.boolean(),
  motivazione_revoca: z.union([z.string(), z.null()]),
  motivazione_sospensione: z.union([z.string(), z.null()]),
  numero_atto: z.string(),
  numero_atto_revoca: z.union([z.string(), z.null()]),
  numero_atto_sospensione: z.union([z.string(), z.null()]),
  superficie_utile_netta: z.number(),
  rimpiazzata_da: z.number().array(),
  superficie_spazi_esterni: z.number().nullable(),
  superficie_spazi_interni: z.number().nullable(),
  spazi_attivita_ordinaria: z.number().nullable(),
  spazi_attivita_comuni: z.number().nullable(),
  spazi_consumo_pasto: z.boolean().nullable(),
  laboratori_atelier: z.boolean().nullable(),
  spazi_riposo: z.boolean().nullable(),
  giardino: z.boolean().nullable(),
  'servizio': z.object({
    'tipologia_servizio': z.object({
      'date_created': z.string().nullable(),
      'date_updated': z.string().nullable(),
      'denominazione': z.string().nullable(),
      'id': z.number().nullable(),
      'sort': z.number().nullable(),
      'status': z.string().nullable(),
      'user_created': z.string().nullable(),
      'user_updated': z.string().nullable(),
      'fascia_eta': z.string().nullable(),
    }),
    'struttura': StrutturaSchemaForQuestionnaire,
  }),
  'titolare': TitolareSchema,
})

export type AutorizzazioneSchemaForQuestionnaire = z.infer<typeof AutorizzazioneSchemaForQuestionnaire>
export const SchedaSchema = z.object({
  'id': z.number(),
  'num_iscritti': z.number().nullable(),
  'num_femmine': z.number().nullable(),
  'num_citt_non_ita': z.number().nullable(),
  'num_con_disabilita': z.number().nullable(),
  'num_in_lista_attesa_31_12': z.number().nullable(),
  'num_femmine_con_disabilita': z.number().nullable(),
  'num_posti_conv_comune': z.number().nullable(),
  'num_posti_conv_altri_pubblici': z.number().nullable(),
  'num_posti_conv_privati': z.number().nullable(),
  'num_iscritti_0_11': z.number().nullable(),
  'num_iscritti_12_23': z.number().nullable(),
  'num_iscritti_24_36': z.number().nullable(),
  'num_iscritti_orario_completo': z.number().nullable(),
  'num_iscritti_orario_completo_disabili': z.number().nullable(),
  'num_iscritti_orario_ridotto': z.number().nullable(),
  'num_iscritti_orario_ridotto_disabili': z.number().nullable(),
  'orario_omogeneo': z.boolean().nullable(),
  'ore_settimanali': z.string().nullable(),
  'num_giorni_settimanali': z.number().nullable(),
  'num_ore_lun': z.string().nullable(),
  'num_ore_mar': z.string().nullable(),
  'num_ore_mer': z.string().nullable(),
  'num_ore_gio': z.string().nullable(),
  'num_ore_ven': z.string().nullable(),
  'num_ore_sab': z.string().nullable(),
  'gruppi_bambini_tot': z.number().nullable(),
  'gruppi_bambini_per_eta': z.string().nullable(),
  'fruitori_antimeridiano_senza_pasto': z.number().nullable(),
  'tot_educatori': z.number().nullable(),
  'tot_educatori_tempo_indeterminato': z.number().nullable(),
  'tot_educatori_orario_completo': z.number().nullable(),
  'tot_educatori_laurea_spec': z.number().nullable(),
  'ore_settimana_educatori_frontale': z.string().nullable(),
  'ore_annuali_progettazione': z.string().nullable(),
  'ore_annuali_formazione_aggiornamento': z.string().nullable(),
  'coord_pedag': z.boolean().nullable(),
  'coord_pedag_da_educatore_struttura': z.boolean().nullable(),
  'coord_pedag_da_figura_unica_struttura': z.boolean().nullable(),
  'coord_pedag_da_figura_piu_strutture': z.boolean().nullable(),
  'partecipaz_coord_pedag_territoriale': z.boolean().nullable(),
  'num_addetti_servizi_ammin': z.number().nullable(),
  'retta_isee': z.boolean().nullable(),
  'retta_presente_quota_iscr_annua': z.boolean().nullable(),
  'retta_presenti_esenzioni_totali': z.boolean().nullable(),
  'retta_previste_tariffe_per_accordi_convenzioni': z.boolean().nullable(),
  'retta_importo_quota_iscr_annua': z.number().nullable(),
  'retta_tariffa_max_tempo_pieno': z.number().nullable(),
  'retta_tariffa_max_tempo_parziale': z.number().nullable(),
  'retta_tariffa_min_tempo_pieno': z.number().nullable(),
  'retta_tariffa_min_tempo_parziale': z.number().nullable(),
  'retta_tariffa_include_mensa_tempo_pieno': z.boolean().nullable(),
  'retta_tariffa_include_mensa_tempo_parziale': z.boolean().nullable(),
  'retta_tariffa_mensa_tempo_pieno': z.number().nullable(),
  'retta_tariffa_mensa_tempo_parziale': z.number().nullable(),
  'bozza': z.boolean().nullable(),
  'data_inizio': z.string().nullable(),
  'data_fine': z.string().nullable(),
  'giorni_apertura': z.number().nullable(),
  'rilevazione': z.object({
    'id': z.number(),
    'data_rilevazione': z.string(),
    'data_inizio_rilevazione': z.string(),
    'data_fine_rilevazione': z.string(),
  }),
  'autorizzazione': AutorizzazioneSchemaForQuestionnaire,
  //todo estrarre?
  'iscritti_stranieri': z.array(z.object({
    'tot_iscritti': z.number(),
    'tot_femmine': z.number(),
    'id_stato_estero': z.object({
      'id': z.number(),
      'denominazione': z.string(),
    }),
  })),
  agevolazioni_figli_ulteriori_primo: z.boolean().nullable(),
  agevolazioni_bambini_con_disabilita: z.boolean().nullable(),
  agevolazioni_genitore_unico: z.boolean().nullable(),
})

export type SchedaSchema = z.infer<typeof SchedaSchema>
export const CountrySchema = z.object({
  id: z.number(),
  sort: z.number().nullable(),
  codice: z.string(),
  denominazione: z.string(),
})

export type CountrySchema = z.infer<typeof CountrySchema>

export const ForeignCountrySchema = z.array(z.object({
  id: z.number(),
  tot_iscritti: z.number(),
  tot_femmine: z.number(),
  scheda: z.object({
    id: z.number(),
  }),
  stato_estero: CountrySchema,
}))

export type ForeignCountrySchema = z.infer<typeof ForeignCountrySchema>

export const TuitionConditionSchema = z.object({
  id: z.number(),
  sort: z.number().nullable(),
  descrizione: z.string(),
})

export type TuitionConditionSchema = z.infer<typeof TuitionConditionSchema>
