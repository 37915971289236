import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { ActiveUser, LogoutResponse } from './authentication'

@Injectable()
export abstract class AuthenticationClient {
  abstract fetchActiveUser(): Observable<ActiveUser | null>

  abstract logoutUser(): Observable<LogoutResponse>
}
