import * as angular from '@angular/common/http'

export type HttpMethod = 'DELETE' | 'GET' | 'HEAD' | 'PATCH' | 'POST' | 'PUT'

export class HttpRequest {
  static new(data: {
    body?: object | null,
    headers?: Partial<Record<string, string | string[]>>,
    method?: HttpMethod,
    pathComponents?: string[],
    queryParams?: Record<string, string | string[]>,
  } = {}): HttpRequest {
    return new HttpRequest(
      data.body ?? null,
      data.headers ?? {},
      data.method ?? 'GET',
      data.pathComponents ?? [],
      data.queryParams ?? {},
    )
  }

  private constructor(
    private readonly body: object | null,
    private readonly headers: Partial<Record<string, string | string[]>>,
    private readonly method: HttpMethod,
    private readonly pathComponents: string[],
    private readonly queryParams: Record<string, string | string[]>,
  ) {
    // empty
  }

  appendingPathComponent(pathComponent: string): HttpRequest {
    return this.clone({ pathComponents: [...this.pathComponents, pathComponent] })
  }

  toAngular(): angular.HttpRequest<unknown> {
    const headers = Object.entries(this.headers).reduce(
      (carry, [name, value]) => value !== undefined ? carry.set(name, value) : carry,
      new angular.HttpHeaders(),
    )

    const params = new angular.HttpParams({ fromObject: this.queryParams })

    switch (this.method) {
      case 'DELETE':
      case 'GET':
      case 'HEAD':
        return new angular.HttpRequest(this.method, this.pathComponents.join('/'), { headers, params })
      case 'PATCH':
      case 'POST':
      case 'PUT':
        return new angular.HttpRequest(this.method, this.pathComponents.join('/'), this.body, { headers, params })
    }
  }

  withBody(body: object | null): HttpRequest {
    return this.clone({ body })
  }

  withHeader(name: string, value: string | string[] | null): HttpRequest {
    const headers = { ...this.headers }

    if (value !== null) {
      headers[name] = value
    } else {
      delete headers[name]
    }

    return this.clone({ headers })
  }

  withMethod(method: HttpMethod): HttpRequest {
    return new HttpRequest(
      this.body,
      this.headers,
      method,
      this.pathComponents,
      this.queryParams,
    )
  }

  withQueryParam(name: string, value: string | string[] | null): HttpRequest {
    const queryParams = { ...this.queryParams }

    if (value !== null) {
      queryParams[name] = value
    } else {
      delete queryParams[name]
    }

    return this.clone({ queryParams })
  }

  private clone(data: {
    body?: object | null,
    headers?: Partial<Record<string, string | string[]>>,
    method?: HttpMethod,
    pathComponents?: string[],
    queryParams?: Record<string, string | string[]>,
  }): HttpRequest {
    return new HttpRequest(
      data.body ?? this.body,
      data.headers ?? this.headers,
      data.method ?? this.method,
      data.pathComponents ?? this.pathComponents,
      data.queryParams ?? this.queryParams,
    )
  }
}
