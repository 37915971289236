import { inject, Injectable } from '@angular/core'
import { ItNotificationService } from 'design-angular-kit'
import {
  catchError, from,
  map,
  Observable,
  of, switchMap,
} from 'rxjs'
import { z } from 'zod'
import { Session } from '../shared-models/server-route/session'
import { StorageClient } from '../storage-client/storage-client'
import { ActiveUser, LogoutResponse } from './authentication'
import { AuthenticationClient } from './authentication-client'
import { ApiClient } from '@api-client'
import { Route } from '@shared-models'

@Injectable()
export class AuthenticationClientLive extends AuthenticationClient {
  private readonly apiClient = inject(ApiClient)
  private readonly notificationService = inject(ItNotificationService)
  private readonly storageClient = inject(StorageClient)

  fetchActiveUser(): Observable<ActiveUser | null> {
    return this.apiClient.decodedApiRequest(
      Route.session(Session.fetchActiveUser()),
      z.object({
        data: z.object({
          id: z.string(),
          first_name: z.string(),
          last_name: z.string(),
          role: z.object({
            name: z.enum(['Administrator', 'Comune', 'Titolare', 'Regione']),
          }),
        }),
      }),
    ).pipe(
      map((response) => response.data),
      map((user) => ({
        id: user.id,
        name: user.first_name,
        surname: user.last_name,
        roleName: user.role.name,
      })),
      catchError((err) => {
        console.log(err)

        this.notificationService.error(
          'Errore',
          'Non è stato possibile recuperare i dati dell\'utente, si prega di ricaricare la pagina.',
        )

        return of(null)
      }),
    )
  }

  logoutUser(): Observable<LogoutResponse> {
    return from(this.storageClient.getData(StorageClient.userToken)).pipe(
      catchError(() => of(null)),
      switchMap((token): Observable<LogoutResponse> => {
        if (token?.value) {
          return from(this.storageClient.remove({ key: StorageClient.userToken })).pipe(
            map((): LogoutResponse => ({ case: 'success' })),
          )
        } else {
          return this.apiClient.noContentApiRequest(Route.session(Session.logoutUser())).pipe(
            map(() => ({ case: 'success' }) as LogoutResponse),
          )
        }
      }),
      catchError((err) => of<LogoutResponse>({ case: 'error', p: { message:`${err}` } })),
    )
  }
}
