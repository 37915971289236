import { Case, makeEnum } from '@technicated/ts-enums'
import { UserRoleNames } from '../user'
import { HttpRequest } from '@shared-models'

class LicensesProto {
  print(this: Licenses, request: HttpRequest): HttpRequest {
    switch (this.case) {
      case 'archive':
        return request.appendingPathComponent(`items/autorizzazione_servizio/${this.p.id}`)
          .withMethod('PATCH')
          .withBody({ archiviata: true })

      case 'holder':
        return request.appendingPathComponent(`items/titolare_autorizzazione/${this.p.id}`)
          .withQueryParam('fields[]',
            '*,' +
            'natura_giuridica.*,' +
            'operatori.id_account.user,' +
            'codice_gestione.id,' +
            'codice_gestione.descrizione',
          )

      case 'holderLegalNatureTypologies':
        return request.appendingPathComponent('items/natura_giuridica_titolare')
          .withQueryParam('limit', '-1')
          .withQueryParam('fields[]', '*.*')

      case 'holderManagementCodes':
        return request.appendingPathComponent('items/codice_gestione_struttura')
          .withQueryParam('limit', '-1')
          .withQueryParam('fields[]', '*.*')

      case 'holdersNames':
        let requestToSend = request.appendingPathComponent('items/titolare_autorizzazione')
          .withQueryParam('fields[]', 'denominazione,id,partita_iva_cf')
          .withQueryParam('limit', '-1')

        if (this.p.substring) {
          requestToSend = requestToSend.withQueryParam('filter[denominazione][_icontains]', this.p.substring)
        }

        if (this.p.userRole === 'Comune') {
          requestToSend = requestToSend.withQueryParam('filter[codice_gestione][descrizione][_icontains]', 'Pubblica')
        }

        return requestToSend
      case 'license':
        return request.appendingPathComponent(`items/autorizzazione_servizio/${this.p.id}`)
          .withQueryParam(
            'fields[]',
            '*,servizio.tipologia_servizio.*,servizio.id,servizio.struttura.id,servizio.struttura.denominazione')

      case 'registerLicense':
        return request.appendingPathComponent('items/autorizzazione_servizio')
          .withQueryParam('fields[]', 'servizio.struttura.*')
          .withMethod('POST')
          .withBody(this.p.data)

      case 'renewLicense':
        return request.appendingPathComponent('items/autorizzazione_servizio')
          .withQueryParam('fields[]', 'servizio.struttura.*')
          .withMethod('POST')
          .withBody(this.p.data)

      case 'revokeLicense':
        return request.appendingPathComponent(`items/autorizzazione_servizio/${this.p.id}`)
          .withMethod('PATCH')
          .withBody(this.p.data)

      case 'suspendLicense':
        return request.appendingPathComponent(`items/autorizzazione_servizio/${this.p.id}`)
          .withMethod('PATCH')
          .withBody(this.p.data)

      case 'types':
        return request.appendingPathComponent('items/tipologia_autorizzazione')
          .withQueryParam('limit', '-1')
          .withQueryParam('fields[]', '*.*')
    }
  }
}

export type Licenses = LicensesProto & (
  | Case<'archive', { id: string }>
  | Case<'holder', { id: string }>
  | Case<'holderLegalNatureTypologies'>
  | Case<'holderManagementCodes'>
  | Case<'holdersNames', { substring?: string, userRole?: UserRoleNames }>
  | Case<'license', { id: number }>
  | Case<'renewLicense', { data: LicenseRenewalData }>
  | Case<'registerLicense', { data: LicenseData }>
  | Case<'revokeLicense', { id: string, data: LicenseRevocationData }>
  | Case<'suspendLicense', { id: string, data: LicenseSuspensionData }>
  | Case<'types'>
  )

export const Licenses = makeEnum<Licenses>({ proto: LicensesProto })

// todo: va trovato un posto migliore a questi modelli
interface HolderData {
  denominazione: string;
  partita_iva_cf: string;
  comune_sede_legale: string;
  indirizzo_sede_legale: string;
  email: string;
  pec: string;
  telefono: string;
  sito_web: string | null;
  legale_rappresentante: string;
  cf_legale_rappresentante: string;
  codice_gestione: number;
  natura_giuridica: number;
}

interface StructureData {
  denominazione: string;
  comune: number;
  indirizzo: string;
  cap: string;
  email: string | null;
  sito_web: string | null;
  telefono: string;
}

interface StructureService {
  tipologia_servizio: number;
  struttura: number | StructureData;
}

export interface LicenseData {
  capacita_ricettiva: number;
  numero_atto: string;
  data_atto_rilascio: string;
  data_inizio_validita: string;
  data_scadenza: string;
  superficie_spazi_esterni: number;
  giardino: boolean;
  superficie_utile_netta: number;
  cucina_interna: boolean;
  spazi_riposo: boolean;
  superficie_spazi_interni: number;
  spazi_attivita_ordinaria: number;
  spazi_attivita_comuni: number;
  laboratori_atelier: boolean;
  spazi_consumo_pasto: boolean;
  locali_gestione_pasti: boolean;
  titolare: number | HolderData;
  servizio: StructureService | number;
  archiviata: boolean;
}

export type LicenseRenewalData = {
  rimpiazza: number
} & LicenseData

export type LicenseSuspensionData = {
  numero_atto_sospensione: string,
  data_atto_sospensione: string,
  data_inizio_sospensione: string,
  data_fine_sospensione: string,
  motivazione_sospensione: string,
}

export type LicenseRevocationData = {
  numero_atto_revoca: string,
  data_atto_revoca: string,
  data_inizio_revoca: string,
  motivazione_revoca: string,
}
