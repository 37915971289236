import { inject } from '@angular/core'
import { NavigationError } from '@angular/router'
import { ItNotificationService } from 'design-angular-kit'

export const NavigationErrorHandler = (e: NavigationError) => {
  console.log(e)

  const notificationService = inject(ItNotificationService)

  notificationService.error(
    'Errore',
    'È stato riscontrato un problema di navigazione, si prega di ricaricare la pagina e di riprovare; '
    + 'se il problema persiste, contattare l\'assistenza.',
  )
}
