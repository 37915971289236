import { Component } from '@angular/core'
import { IonApp, IonRouterOutlet } from '@ionic/angular/standalone'
import { ItNotificationsComponent, NotificationPosition } from 'design-angular-kit'

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  standalone: true,
  imports: [IonApp, IonRouterOutlet, ItNotificationsComponent],
})
export class AppComponent {
  constructor() {}

  protected readonly NotificationPosition = NotificationPosition
}
