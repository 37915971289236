import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { inject, Injectable } from '@angular/core'
import { from, switchMap } from 'rxjs'
import { StorageClient } from '../../storage-client/storage-client'

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private readonly storageClient = inject(StorageClient)

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    return from(this.storageClient.getData(StorageClient.userToken))
      .pipe(
        switchMap((token) => {
          const authToken = token.value

          if (authToken) {
            req = req.clone({
              headers: req.headers.set('Authorization', `Bearer ${authToken}`),
            })
          } else {
            req = req.clone({
              withCredentials: true,
            })
          }

          return next.handle(req)
        }),
      )
  }
}
