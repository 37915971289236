import { inject } from '@angular/core'
import { CanActivateFn, Router, UrlTree } from '@angular/router'
import { Observable, of } from 'rxjs'
import { SessionClient } from '../../session-client/session-client'

export const AuthGuard: CanActivateFn = (_): Observable<UrlTree | boolean> => {
  const sessionClient = inject(SessionClient)
  const router = inject(Router)

  if (sessionClient.activeUser()) {
    return of(true)
  } else {
    return of(router.createUrlTree(['login']))
  }
}
