import { inject, Injectable } from '@angular/core'
import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms'
import { catchError, map, Observable, of, switchMap, timer } from 'rxjs'
import { z } from 'zod'
import { Users } from '../shared-models/server-route/users'
import { ApiClient } from '@api-client'
import { Route } from '@shared-models'
@Injectable()
export class ApiValidatorsService {
  apiClient = inject((ApiClient))

  userEmailIsUnique(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      if (!control.value) {
        return of(null) // Return null if the control value is empty
      }

      return timer(300).pipe(
        switchMap(() =>
          this.apiClient.decodedApiRequest(
            Route.users(Users.searchForEmail({ email: control.value })),
            z.object({ data: z.object({}).array() }),
          ).pipe(
            map((res) => res.data),
            map((objectsWithSameVatNumberOrTaxCode) =>
              objectsWithSameVatNumberOrTaxCode.length === 0 ? null : { valueIsNotUnique: true },
            ),
            catchError((e) => {
              console.log(e)
              return of({ valueIsNotUnique: true })
            }),
          ),
        ),
      )
    }
  }

  valueIsUnique(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      if (!control.value) {
        return of(null) // Return null if the control value is empty
      }

      return timer(300).pipe(
        switchMap(() =>
          this.apiClient.decodedApiRequest(
            Route.searchForVatNumberOrTaxCode({ vatCodeOrTaxNumber: control.value }),
            z.object({ data: z.object({}).array() }),
          ).pipe(
            map((res) => res.data),
            map((objectsWithSameVatNumberOrTaxCode) =>
              objectsWithSameVatNumberOrTaxCode.length === 0 ? null : { valueIsNotUnique: true },
            ),
            catchError((e) => {
              console.log(e)
              return of({ valueIsNotUnique: true })
            }),
          ),
        ),
      )
    }
  }

  userTaxCodeValueIsUnique(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      if (!control.value) {
        return of(null) // Return null if the control value is empty
      }

      return timer(300).pipe(
        switchMap(() =>
          this.apiClient.decodedApiRequest(
            Route.users(Users.searchForTaxCode({ taxCode: control.value })),
            z.object({ data: z.object({}).array() }),
          ).pipe(
            map((res) => res.data),
            map((objectsWithSameVatNumberOrTaxCode) =>
              objectsWithSameVatNumberOrTaxCode.length === 0 ? null : { valueIsNotUnique: true },
            ),
            catchError((e) => {
              console.log(e)
              return of({ valueIsNotUnique: true })
            }),
          ),
        ),
      )
    }
  }
}
