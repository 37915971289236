import { Choice } from '@technicated/ts-enums'

export type LicenseStatus =
  | Choice<'active'>
  | Choice<'archived'>
  | Choice<'expired'>
  | Choice<'expiring'>
  | Choice<'replaced'>
  | Choice<'revoked'>
  | Choice<'suspended'>
